/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
class AddNewQuotation extends React.Component {
  
  state = {
    loading:false,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownarea:false,
    areadropdowntext:'',
    Billtoparty:localStorage.getItem('Billtoparty'),
    lasteditby:localStorage.getItem('Ref'),
    arealist:[],
    shippervalue:'',
    addressvalue:'',
    picvalue:'',
    depogatechargevalue:'',
    contactvalue:'',
    backtobackchargevalue:'',
    gp20pgu:'',
    isotank20pgu:'',
    rf20pgu:'',
    ot20pgu:'',
    tipper20pgu:'',
    gp40pgu:'',
    rf40pgu:'',
    isotank40pgu:'',
    tipper40pgu:'',
    gp20ptp:'',
    isotank20ptp:'',
    rf20ptp:'',
    ot20ptp:'',
    tipper20ptp:'',
    gp40ptp:'',
    rf40ptp:'',
    isotank40ptp:'',
    tipper40ptp:'',
    aftersubmitstatus:0,
    
  };
  
  componentDidMount(){
    this.getarealist();
  }
  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
 

  addnewquotationcallapi =()=>{
    console.log("clicked")
    this.setState({aftersubmitstatus:3})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper",this.state.shippervalue );
    urlencoded.append("address", this.state.addressvalue);
    urlencoded.append("area", this.state.areadropdowntext);
    urlencoded.append("pic", this.state.picvalue);
    urlencoded.append("depogatecharge", this.state.depogatechargevalue);
    urlencoded.append("contact", this.state.contactvalue);
    urlencoded.append("backtobackcharge", this.state.backtobackchargevalue);
    urlencoded.append("20GPUGHCpgu", this.state.gp20pgu);
    urlencoded.append("20RFpgu", this.state.rf20pgu);
    urlencoded.append("20ISOTANKpgu", this.state.isotank20pgu);
    urlencoded.append("20OTpgu",this.state.ot20pgu );
    urlencoded.append("20Tipperpgu", this.state.tipper20pgu);
    urlencoded.append("40GPUGHCpgu", this.state.gp40pgu);
    urlencoded.append("40RFpgu", this.state.rf40pgu);
    urlencoded.append("40ISOTANKpgu", this.state.isotank40pgu);
    urlencoded.append("40Tipperpgu", this.state.tipper40pgu);
    urlencoded.append("20GPUGHCptp", this.state.gp20ptp);
    urlencoded.append("20RFptp", this.state.rf20ptp);
    urlencoded.append("20ISOTANKptp", this.state.isotank20ptp);
    urlencoded.append("20OTptp", this.state.ot20ptp);
    urlencoded.append("20Tipperptp", this.state.tipper20ptp);
    urlencoded.append("40GPUGHCptp", this.state.gp40ptp );
    urlencoded.append("40RFptp", this.state.rf40ptp);
    urlencoded.append("40ISOTANKptp",this.state.isotank40ptp );
    urlencoded.append("40Tipperptp", this.state.tipper40ptp);
    urlencoded.append("lasteditby", this.state.lasteditby);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-addnewquotationapi'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //success
            this.setState({aftersubmitstatus:1})

            //console.log(json.user)
            //  var lengthemployee = Object.keys(json.user).length;
            //  for(let i=0;i<lengthemployee;i++){

            //   //status need do
            //    this.setState({rotpendinglist:[...this.state.rotpendinglist,
            //     { no:i+1,
            //       rotno:json.user[i].Rotnumber,
            //       shippername:json.user[i].Shipper,
            //       totalvolume:json.user[i].totalvolume,
            //       bookingno:json.user[i].Bookingno,
            //       status:1,
            //       remark:json.user[i].Remark,
            //     },            
            //   ]})
              
              
             
            //    console.log(this.state.rotpendinglist)
             

             
            //   }
              this.setState({loading:false})  
            }        
               
         else{
           //fail submit
          this.setState({aftersubmitstatus:2})
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
  getarealist = () =>{
    this.setState({loading:true})
    const temparealist = [];
    var urlencoded = new URLSearchParams();
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getarealist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({arealist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({arealist:[...this.state.arealist,
                { no:i+1,
                  area:json.user[i].Area,
                },            
              ]})
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
  
  
  buttonclickedtocreatenewquotation =() =>{
      this.setState({aftersubmitstatus:0})
  }

  renderListarea() {
      

    return this.state.arealist.map((data, index) => {
       const { no, area} = data //destructuring

       return (
        <DropdownItem onClick={() => this.setState({areadropdowntext:area})}>{area}</DropdownItem>
       )
    })
 }
  

 




  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Create New Quotation:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
                {/* Success Status*/}
                {this.state.aftersubmitstatus == 1
              ?             
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Quotation Number is 12122312.</p>
             
               <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/quotation"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Go To Quotation
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
              <Col md="12" >
            <img 
            className="img-center"
            width="120"
            height="120"
            alt="..."
            class
            src={require("./../../assets/gif/lorrygif3.gif")}
          />
          </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body">
  <Form>
  <Row>
    <Col md="12">
      <p className="h5">Bill To Party:</p>
      <FormGroup>
        <Input
          id="Billtopartyinput1"
          placeholder="Bill To Party"
          disabled
          type="text"
          value={this.state.Billtoparty}
        />
      </FormGroup>
    </Col>
    
  </Row>
  <Row>
  <Col md="12">
      <p className="h5">Shipper/Consignee:</p>
      <FormGroup>
      <Input
          id="Shipperinput1"
          placeholder="Shipper/Consignee"
          type="text"
          value={this.state.shippervalue} 
          onChange={e => this.setState({shippervalue: e.target.value})}
        />
      </FormGroup>
    </Col>
   
  </Row>
  <Row>
  <Col md="12">
      <p className="h5">Address:</p>
      <FormGroup>
      <Input
          id="Addressinput1"
          placeholder="Address"
          type="text"
          value={this.state.addressvalue} 
          onChange={e => this.setState({addressvalue: e.target.value})}
        />
      </FormGroup>
    </Col>
   
  </Row>
  <Row>
  <Col md="6">
      <p className="h5">Area:</p>
      <FormGroup>
      <Dropdown className = "btn-block" block={true} size="lg"  isOpen={this.state.isopendropdownarea} toggle={() => this.toggledropdownarea("isopendropdownarea")}>
          <DropdownToggle caret className = "btn-block" block={true}>
            {this.state.areadropdowntext}
            </DropdownToggle>
          <DropdownMenu>
              {this.renderListarea()}
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
      </Col>
  <Col md="6">
      <p className="h5">PIC:</p>
      <FormGroup>
      <Input
          id="Picinput1"
          placeholder="PIC"
          type="text"
          value={this.state.picvalue} 
          onChange={e => this.setState({picvalue: e.target.value})}
        />
      </FormGroup>
    </Col>
   
  </Row>
  <Row>
  <Col md="6">
      <p className="h5">Depo Gate Charge:</p>
      <FormGroup>
      <Input
          id="Depogatechargeinput1"
          placeholder="Depo Gate Charge"
          type="number"
          min="0"
          value={this.state.depogatechargevalue} 
          onChange={e => this.setState({depogatechargevalue: e.target.value})}
        />
      </FormGroup>
      </Col>
  <Col md="6">
      <p className="h5">Contact:</p>
      <FormGroup>
      <Input
          id="Contactinput1"
          placeholder="Contact"
          type="text"
          value={this.state.contactvalue} 
          onChange={e => this.setState({contactvalue: e.target.value})}
        />
      </FormGroup>
    </Col>
   
  </Row>
  <Row>
  <Col md="6">
      <p className="h5">Back to Back Charge:</p>
      <FormGroup>
      <Input
          id="Backtobackchargeinput1"
          placeholder="Back to Back Charge"
          type="number"
          min="0"
          value={this.state.backtobackchargevalue} 
          onChange={e => this.setState({backtobackchargevalue: e.target.value})}
        />
      </FormGroup>
      </Col>
 
  </Row>
  <Row>
  <Col md="6">
    
  <Table className="align-items-center" responsive>
                <thead className="thead-light-blue">
                  <tr>
                  <th scope="col">Size</th>
                    <th scope="col">Type</th>
                    <th scope="col">Price</th>    
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>20</td>
                    <td>GP/UG/HC</td>
                    <td>
                      <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.gp20pgu} 
          onChange={e => this.setState({gp20pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  
                  <tr>
                    <td>20</td>
                    <td>RF</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.rf20pgu} 
          onChange={e => this.setState({rf20pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>ISOTANK</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.isotank20pgu} 
          onChange={e => this.setState({isotank20pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>OT</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.ot20pgu} 
          onChange={e => this.setState({ot20pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>Tipper</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.tipper20pgu} 
          onChange={e => this.setState({tipper20pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>GP/UG/HC</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.gp40pgu} 
          onChange={e => this.setState({gp40pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>RF</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.rf40pgu} 
          onChange={e => this.setState({rf40pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>ISOTANK</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.isotank40pgu} 
          onChange={e => this.setState({isotank40pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>Tipper</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.tipper40pgu} 
          onChange={e => this.setState({tipper40pgu: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                </tbody>
              </Table>
              
  </Col>
  <Col md="6">
      
  <Table className="align-items-center" responsive>
                <thead className="thead-light-blue">
                  <tr>
                    <th scope="col">Size</th>
                    <th scope="col">Type</th>
                    <th scope="col">Price</th>    
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>20</td>
                    <td>GP/UG/HC</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.gp20ptp} 
          onChange={e => this.setState({gp20ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  
                  <tr>
                    <td>20</td>
                    <td>RF</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.rf20ptp} 
          onChange={e => this.setState({rf20ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>ISOTANK</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.isotank20ptp} 
          onChange={e => this.setState({isotank20ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>OT</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.ot20ptp} 
          onChange={e => this.setState({ot20ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>Tipper</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.tipper20ptp} 
          onChange={e => this.setState({tipper20ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>GP/UG/HC</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.gp40ptp} 
          onChange={e => this.setState({gp40ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>RF</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.rf40ptp} 
          onChange={e => this.setState({rf40ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>ISOTANK</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0"
          type="number"
          min="0"
          value={this.state.isotank40ptp} 
          onChange={e => this.setState({isotank40ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>Tipper</td>
                    <td> <FormGroup>
      <Input
          id="gp20pgu"
          placeholder="0" 
          type="number"
          min="0"
          value={this.state.tipper40ptp} 
          onChange={e => this.setState({tipper40ptp: e.target.value})}
        />
      </FormGroup></td>  
                     
                  </tr>
                </tbody>
              </Table>
              
    </Col>
   
  </Row>

</Form>
  </div>
  <div className="modal-footer">
    <Button
      color="secondary"
      data-dismiss="modal"
      type="button"
      onClick={() => this.toggleModal("editpopuout")}
    >
      Close
    </Button>
    <Button color="primary" type="button" onClick={this.addnewquotationcallapi.bind(this)}>
      Save changes
    </Button>
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              </Card>
            </div>
          </Row>
        </Container>
     
       

      
      </>
    );
  }
}

export default AddNewQuotation;
