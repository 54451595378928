/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
// reactstrap components
import { Card,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
// mapTypeId={google.maps.MapTypeId.ROADMAP}
// const MapWrapper = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={12}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "administrative",
//             elementType: "labels.text.fill",
//             stylers: [{ color: "#444444" }]
//           },
//           {
//             featureType: "landscape",
//             elementType: "all",
//             stylers: [{ color: "#f2f2f2" }]
//           },
//           {
//             featureType: "poi",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "road",
//             elementType: "all",
//             stylers: [{ saturation: -100 }, { lightness: 45 }]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "all",
//             stylers: [{ visibility: "simplified" }]
//           },
//           {
//             featureType: "road.arterial",
//             elementType: "labels.icon",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "transit",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "water",
//             elementType: "all",
//             stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
//           }
//         ]
//       }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );

class Orders extends React.Component {
  state = {
    tabs: 2,
    tabsimportexport:1,
    detailpopout:false,
    detailorderpopout:false,
    detailprocesspopout:false,
    rotpendinglist:[],
    detailpendinglist:[],
    detailorderlist:[],
    completelist:[],
    Billtoparty:localStorage.getItem('Billtoparty'),
    loadingmodal:false,
    loadingmodalorder:false,
    loadingmodalprocess:false,
    loading:false,
    shipper:'',
    bookingno:'',
    pol:'',
    pod:'',
    shipperorder:'',
    bookingnoorder:'',
    polorder:'',
    podorder:'',
    rotnumbermodalpending:'',
    rotnumbermodalordertab:'',
    consignmentnoprocesstab:'',
    statusprocesstab:'',
    containernoprocesstab:'',
    requiredateprocesstab:'',
    closingdateprocesstab:'',
    typeprocesstab:'',
    color1:'gray',
    color2:'gray',
    color3:'gray',
    color4:'gray',
    color5:'gray',
    color6:'gray',
    buttoncolor1:'white',
    buttoncolor2:'primary',
    buttoncolor3:'white',
  };
  // toggleModal = state => {
  //   this.setState({
  //     [state]: !this.state[state]
  //   });
  // };

  toggleModalwithfunctiondetailpopout = (rotnumber,openclosetruefalse,completeorpendingtab) =>{
    this.setState({detailpopout:openclosetruefalse})
    //function call
    if(completeorpendingtab == "complete"){
      this.showmodalapifunctioncalldetailcompletetab(rotnumber);
    }else if(completeorpendingtab == "pending"){
      this.showmodalapifunctioncalldetail(rotnumber);
    }
    
  }

  toggleModalwithfunctiondetailorderpopout = (rotnumber,openclosetruefalse) =>{
    this.setState({detailorderpopout:openclosetruefalse})
    //function call
    this.showmodalapifunctioncalldetailordertab(rotnumber);
  }

  toggleModalwithfunctiondetailprocesspopout = (rotnumber,openclosetruefalse,consignmentno,status,containerno,requiredate,closingdate,type) =>{
    this.setState({detailprocesspopout:openclosetruefalse})
    this.setState({
      statusprocesstab:status,
      containernoprocesstab:containerno,
      requiredateprocesstab:requiredate,
      closingdateprocesstab:closingdate,
      typeprocesstab:type,
      consignmentnoprocesstab:consignmentno,
    })

    if(status =='Assigning Driver'){
      this.setState({
        color1:'warning',
        color2:'gray',
        color3:'gray',
        color4:'gray',
        color5:'gray',
        color6:'gray',
      })
    }else if(status =='Staging In'){
      this.setState({
        color1:'success',
        color2:'warning',
        color3:'gray',
        color4:'gray',
        color5:'gray',
        color6:'gray',
      })
    }else if(status =='Truck In'){
      this.setState({
        color1:'success',
        color2:'success',
        color3:'warning',
        color4:'gray',
        color5:'gray',
        color6:'gray',
      })
    }else if(status =='Staging Out'){
      this.setState({
        color1:'success',
        color2:'success',
        color3:'success',
        color4:'warning',
        color5:'gray',
        color6:'gray',
      })
    }else if(status =='Truck Out'){
      this.setState({
        color1:'success',
        color2:'success',
        color3:'success',
        color4:'success',
        color5:'warning',
        color6:'gray',
      })
    }else if(status =='Completed'){
      this.setState({
        color1:'success',
        color2:'success',
        color3:'success',
        color4:'success',
        color5:'success',
        color6:'warning',
      })
    }
    //function call
    //this.showmodalapifunctioncalldetail(rotnumber);
  }
  
  // toggleNavs = (e, state, index) => {
  //   e.preventDefault();
  //   this.setState({
  //     [state]: index
  //   });
  //   if(state == "tabsimportexport" ){
  //     this.getorderlistapi("Import");
  //   }else if(index ==1){
  //     this.getorderlistapi("Import");
  //   }
  // };

  buttonclicktoswitchtab = (e,tabid,tabsimportexport) =>{
    e.preventDefault();
    this.setState({tabs:tabid})
    this.setState({tabsimportexport:tabsimportexport})
    if(tabid === 1 && tabsimportexport === 1){
      this.getpendingorderlistapi("Import");
    }else if(tabid === 1 && tabsimportexport === 2){
      this.getpendingorderlistapi("Export");
    }else if(tabid === 2 && tabsimportexport === 1){
      this.getorderlistapi("Import");
    }else if(tabid === 2 && tabsimportexport === 2){
      this.getorderlistapi("Export");
    }else if(tabid === 3 && tabsimportexport === 1){
      this.getcompletedorderapi("Import");
    }else if(tabid === 3 && tabsimportexport === 2){
      this.getcompletedorderapi("Export");
    }
    //console.log("tabid:"+tabid)
    //console.log("tabsimportexport:"+tabsimportexport)
  }

  componentDidMount(){
   // this.getpendingorderlistapi("Import");
    this.getorderlistapi("Import");
    this.setState({tabsimportexport:1})
  }


  getpendingorderlistapi = (importexport) =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotpending'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              //status need do
               this.setState({rotpendinglist:[...this.state.rotpendinglist,
                { no:i+1,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper,
                  totalvolume:json.user[i].totalvolume,
                  bookingno:json.user[i].Bookingno,
                  status:1,
                  remark:json.user[i].Remark,
                },            
              ]})
              
              
             
               console.log(this.state.rotpendinglist)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
  completedbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"white"})
    this.setState({buttoncolor3:"primary"})
    this.setState({tabs:3})
    this.setState({tabsimportexport:1})
    this.getcompletedorderapi();

  }


  orderbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})
    this.setState({buttoncolor3:"white"})
    this.setState({tabs:2})
    this.setState({tabsimportexport:1})
    this.getorderlistapi();
  
  }

  pendingorderbuttonclicked = () =>{
    this.setState({buttoncolor1:"primary"})
    this.setState({buttoncolor2:"white"})
    this.setState({buttoncolor3:"white"})
    this.setState({tabs:1})   
    this.setState({tabsimportexport:1})
    this.getpendingorderlistapi();
    
  }

  getorderlistapi = (importexport) =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotorderlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({rotpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              //status need do
               this.setState({rotpendinglist:[...this.state.rotpendinglist,
                { no:i+1,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper,
                  totalvolume:json.user[i].totalvolume,
                  bookingno:json.user[i].Bookingno,
                  status:1,
                  remark:json.user[i].Remark,
                },            
              ]})
              
              
             
               console.log(this.state.rotpendinglist)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getcompletedorderapi = (importexport) =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("importexport", importexport);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotordercompletedlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({completelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              //status need do
               this.setState({completelist:[...this.state.completelist,
                { no:i+1,
                  rotno:json.user[i].Rotnumber,
                  shippername:json.user[i].Shipper,
                  totalvolume:json.user[i].totalvolume,
                  bookingno:json.user[i].Bookingno,
                  status:1,
                  remark:json.user[i].Remark,
                },            
              ]})
              
              
             
               console.log(this.state.completelist)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
  
  showmodalapifunctioncalldetail = (rotnumber) =>{
    this.setState({loadingmodal:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotdetailpendingdata'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
            this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            this.setState({detailpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({detailpendinglist:[...this.state.detailpendinglist,
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate,
                  closedate:json.user[i].Closingday,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state.detailpendinglist)
             

             
              }
              this.setState({loadingmodal:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }


  showmodalapifunctioncalldetailcompletetab = (rotnumber) =>{
    this.setState({loadingmodal:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotdetailpendingdatacompletetab'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            this.setState({detailpendinglist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({detailpendinglist:[...this.state.detailpendinglist,
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate,
                  closedate:json.user[i].Closingday,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state.detailpendinglist)
             

             
              }
              this.setState({loadingmodal:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }


  showmodalapifunctioncalldetailordertab = (rotnumber) =>{
    this.setState({loadingmodalorder:true})
    console.log(rotnumber)
    console.log("I Am Called")
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getrotdetailpendingdataordertab'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        this.setState({rotnumbermodalordertab:rotnumber})
            console.log(json)
            this.setState({detailorderlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipperorder:json.user[i].Shipper,
                bookingnoorder:json.user[i].Bookingno,
                polorder:json.user[i].POL,
                podorder:json.user[i].POD,
              })
              //status need do
               this.setState({detailorderlist:[...this.state.detailorderlist,
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate,
                  closedate:json.user[i].Closingday,
                  type:json.user[i].Type,
                  activitystagingindate:json.user[i].Activitydatestaging,
                  activtiytruckindate:json.user[i].Activitydatetruckin,
                  activitystagingoutdate:json.user[i].Activitydatestagingout,
                  activitytruckoutdate:json.user[i].Activitydatetruckout,
                  activitytransferbilldate:json.user[i].transferbilldate,
                },            
              ]})
              
              
             
               console.log(this.state.detailorderlist)
             

             
              }
              this.setState({loadingmodalorder:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }


  renderPendingOrderData() {
      

    return this.state.rotpendinglist.map((data, index) => {
       const { no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
       return (

        
        <tr>
       <td>{no})</td>
       <td>{rotno}</td>
          <th scope="row">
         
                <span className="mb-0 text-sm" >
                  {shippername}
                </span>
             
          </th>
       <td>{totalvolume}</td>
          <td>{bookingno}</td>     
          {/* <td>
            <Badge color="" className="badge-dot mr-4">
              <i className="bg-warning" />
              {remark}
            </Badge>
          </td>  */}
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  href="#pablo"
                  onClick={() => this.toggleModalwithfunctiondetailpopout(rotno,true,"pending")}
                >
                  Detail
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Delete
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
       
   
       )
    })
  }


  rendercompletedOrderData() {
      

    return this.state.completelist.map((data, index) => {
       const { no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
       return (

        
        <tr>
       <td>{no})</td>
       <td>{rotno}</td>
          <th scope="row">
         
                <span className="mb-0 text-sm" >
                  {shippername}
                </span>
             
          </th>
       <td>{totalvolume}</td>
          <td>{bookingno}</td>     
          {/* <td>
            <Badge color="" className="badge-dot mr-4">
              <i className="bg-warning" />
              {remark}
            </Badge>
          </td>  */}
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  href="#pablo"
                  onClick={() => this.toggleModalwithfunctiondetailpopout(rotno,true,"complete")}
                >
                  Detail
                </DropdownItem>
                {/* <DropdownItem
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Delete
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
       
   
       )
    })
  }


  renderOrderListData() {
      

  return this.state.rotpendinglist.map((data, index) => {
     const { no, rotno, shippername, totalvolume ,bookingno,status,remark} = data //destructuring
     return (

      
      <tr>
     <td>{no})</td>
     <td>{rotno}</td>
        <th scope="row">
       
              <span className="mb-0 text-sm" >
                {shippername}
              </span>
           
        </th>
     <td>{totalvolume}</td>
        <td>{bookingno}</td>     
        <td>{status}/10</td>   
        {/* <td>
          <Badge color="" className="badge-dot mr-4">
            <i className="bg-warning" />
            {remark}
          </Badge>
        </td>  */}
        <td className="text-right">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={e => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#pablo"
                onClick={() => this.toggleModalwithfunctiondetailorderpopout(rotno,true)}
              >
                Detail
              </DropdownItem>
              {/* <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Delete
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Edit
              </DropdownItem> */}
                  <div>
        <ReactToPrint
          trigger={() =>  <DropdownItem
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            Print
          </DropdownItem>}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}><ComponentToPrint ref={el => (this.componentRef = el)} /></div>
      </div>
             
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
     
 
     )
  })
  }

  renderdetaillistData() {
      

    return this.state.detailpendinglist.map((data, index) => {
       const { no, shipper, bookingno, pol ,pod,consignmentno,containerno,requiredate,closedate,type} = data //destructuring
       var requiredateshow =''
       if(requiredate == 946684800){
          requiredateshow = 'TBC'
       }else{
        const requiredateshowday = new Date(requiredate*1000).getDate()
        const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
        const requiredateshowyear = new Date(requiredate*1000).getFullYear()
         requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
 
     
       }
       const closedateshowday = new Date(closedate*1000).getDate()
       const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
       const closedateshowyear = new Date(closedate*1000).getFullYear()
       const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear

       return (
      
                  <tbody>
                    <tr>
                      <td>{no})</td>
                      <td>{consignmentno}</td>
                      <td>{containerno}</td>
                      <td>{requiredateshow}</td>
                      <td>{closedateshow}</td>
                      <td>{type}</td>
                    </tr>                   
                   
                  </tbody>
               
       )
    })
  }

  renderdetaillistDataOrder() {
      

    return this.state.detailorderlist.map((data, index) => {
       const { no, shipper, bookingno, pol ,pod,consignmentno,containerno,requiredate,closedate,type,activitystagingindate,activtiytruckindate,activitystagingoutdate,activitytruckoutdate,activitytransferbilldate} = data //destructuring
       var requiredateshow =''
       var status=''
        console.log(consignmentno)
       if(requiredate == 946684800){
          requiredateshow = 'TBC'
       }else{
        const requiredateshowday = new Date(requiredate*1000).getDate()
        const requiredateshowmonth = new Date(requiredate*1000).getMonth() +1 
        const requiredateshowyear = new Date(requiredate*1000).getFullYear()
         requiredateshow = requiredateshowday +'/' + requiredateshowmonth + '/' + requiredateshowyear
 
     
       }
       const closedateshowday = new Date(closedate*1000).getDate()
       const closedateshowmonth = new Date(closedate*1000).getMonth() +1 
       const closedateshowyear = new Date(closedate*1000).getFullYear()
       const closedateshow = closedateshowday +'/' + closedateshowmonth + '/' + closedateshowyear


       if(activitytransferbilldate != 946684800){
        status ='Completed'
       }else if(activitytruckoutdate != 946684800){
        status ='Truck Out'
       }else if(activitystagingoutdate != 946684800){
        status ='Staging Out'
       }else if(activtiytruckindate != 946684800){
        status ='Truck In'
       }else if(activitystagingindate != 946684800){
        status ='Staging In'
       }else{
        status ='Assigning Driver'
       }

       return (
        <tbody>
            <tr>
              <td>{no})</td>
              <td>{consignmentno}</td>
              <td>{bookingno}</td>
              <td>{requiredateshow}</td>
              <td>{closedateshow}</td>
              <td>{type}</td>
              <td>
                    <Badge color="" className="badge-dot mr-4">
                      <i className="bg-warning" />
                      {status}
                    </Badge>
              </td>
              <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={() => this.toggleModalwithfunctiondetailprocesspopout(10,true,consignmentno,status,containerno,requiredateshow,closedateshow,type)}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                {/* <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#pablo"
                    onClick={() => this.toggleModalwithfunctiondetailprocesspopout(10,true,consignmentno,status,containerno,requiredateshow,closedateshow,type)}
                  >
                    Detail
                  </DropdownItem>
                 
                </DropdownMenu> */}
              </UncontrolledDropdown>
            </td>
           
              </tr>
        </tbody>
    
               
       )
    })
  }

 

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col-7 ">
            {/* <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 1
                })}
                onClick={e => 
                (this.buttonclicktoswitchtab(e,1,1))
              }
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-planet mr-2" />
                Pending Approval
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 2
                })}
                onClick={e => 
                  (this.buttonclicktoswitchtab(e,2,1))
                }
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-collection  mr-2" />
                Orders
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 3
                })}
                onClick={e =>
                  (this.buttonclicktoswitchtab(e,3,1))}
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-check-bold  mr-2" />
                Completed
              </NavLink>
            </NavItem>
          </Nav>
        </div> */}<Col md="12  mt-3">
            
                <div className="d-md-inline-block">
                <NavLink className="nav-link-icon d-md-inline-block" to="/admin/addnewrot" tag={Link}>
              <Button className="my-4" color="default" type="button" style={{marginLeft:-10}}>
                + Add New ROT
              </Button>
              </NavLink>
              <NavLink className="nav-link-icon d-md-inline-block" >
              <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor1} type="button" style={{marginLeft:-10}} onClick={(e)=>this.pendingorderbuttonclicked()}>
              <i className="ni ni-planet mr-2" />
                 Pending Approval
              </Button>
              </NavLink>
              <NavLink className="nav-link-icon d-md-inline-block" >
              <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor3} type="button" style={{marginLeft:-10}} onClick={(e)=> this.completedbuttonclicked() }>
              <i className="ni ni-check-bold mr-2" />
                 Completed
              </Button>
              </NavLink>
                </div>
         
                <NavLink className="nav-link-icon ">
              <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor2} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.orderbuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
                 Orders
              </Button>
              </NavLink>
              
                
            </Col>
            </div>
            <div className=" col-12 ">
              <Card className=" shadow  ">
              <Row>
                <Col >

                <div className=" col-12 d-md-inline-block ">
                <div className="nav-wrapper ">
              <Nav
                className="nav-fill flex-column flex-md-row "
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabsimportexport === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabsimportexport === 1
                    })}
                    onClick={e => (this.buttonclicktoswitchtab(e,this.state.tabs,1))}
                    href="#pablo"
                    role="tab"
                  >
                    <i className="ni ni-world mr-2" />
                    Import
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.tabsimportexport === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.state.tabsimportexport === 2
                    })}
                    onClick={e =>(this.buttonclicktoswitchtab(e,this.state.tabs,2))}
                    href="#pablo"
                    role="tab"
                  >
                    <i className="ni ni-world-2  mr-2" />
                    Export
                  </NavLink>
                </NavItem>
                
              </Nav>
            </div>
                </div>
                
               
                </Col>

                <Col md="8 ">
             
              
                 
                <Form className="  navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ">

                <FormGroup className=" ml-md-auto">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search" type="text" />
                  </InputGroup>
                </FormGroup>
                </Form>

                </Col>
              </Row>
              
              
              <CardBody >
                

                
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              class
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
               
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">

                  <Table className="align-items-center  " responsive>
                  <thead className="thead-light">
                    
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">ROT No.</th>
                      <th scope="col">Shipper/Consignee</th>      
                      <th scope="col">Volume</th>                  
                      <th scope="col">Booking No.</th>
                      {/* <th scope="col">Remark</th> */}
                      <th scope="col">Edit</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                      {this.renderPendingOrderData()} 
                      </tbody>
                      </Table>
                      
                  </TabPane>
                    
                  <TabPane tabId="tabs2andtabsimportexport1">

                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">ROT No.</th>
                      <th scope="col">Shipper/Consignee</th>      
                      <th scope="col">Volume</th>                  
                      <th scope="col">Booking No.</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col">Remark</th> */}
                      <th scope="col">Edit</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                      {this.renderOrderListData()} 
                      </tbody>
                      </Table>
                      
                  </TabPane>

                  <TabPane tabId="tabs3andtabsimportexport1">

                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">ROT No.</th>
                      <th scope="col">Shipper/Consignee</th>      
                      <th scope="col">Volume</th>                  
                      <th scope="col">Booking No.</th>
                      {/* <th scope="col">Remark</th> */}
                      <th scope="col">Edit</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                      {this.rendercompletedOrderData()} 
                      </tbody>
                      </Table>
                      
                  </TabPane>

                  <TabPane tabId="tabs1andtabsimportexport2">

                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">ROT No.</th>
                      <th scope="col">Shipper/Consignee</th>      
                      <th scope="col">Volume</th>                  
                      <th scope="col">Booking No.</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Edit</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                      {this.renderPendingOrderData()} 
                      </tbody>
                      </Table>
                      
                  </TabPane>


                  <TabPane tabId="tabs2andtabsimportexport2">
                  <Table className="align-items-center" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ROT No.</th>
                            <th scope="col">Shipper/Consignee</th>      
                            <th scope="col">Volume</th>                  
                            <th scope="col">Booking No.</th>
                            <th scope="col">Status</th>
                            {/* <th scope="col">Remark</th> */}
                            <th scope="col">Edit</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                        {this.renderOrderListData()} 
                        </tbody>
                      </Table>
                  </TabPane>


                  <TabPane tabId="tabs3andtabsimportexport2">
                  <Table className="align-items-center" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">ROT No.</th>
                            <th scope="col">Shipper/Consignee</th>      
                            <th scope="col">Volume</th>                  
                            <th scope="col">Booking No.</th>
                            {/* <th scope="col">Remark</th> */}
                            <th scope="col">Edit</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                       
                        {this.rendercompletedOrderData()} 
                        </tbody>
                      </Table>
                  </TabPane>


                </TabContent>
              
                  }
              </CardBody>
                
              </Card>
            </div>
          </Row>
          
        </Container>
      
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailpopout}
          toggle={() => this.setState({detailpopout:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         class
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
          <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailpopoutLabel">
              ROT No: {this.state.rotnumbermodalpending}
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
        <Form>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
            Shipper/Consignee
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.shipper}
          </h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
          Booking No
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
          : {this.state.bookingno}
          </h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
          POL
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
          : {this.state.pol}
          </h5>
          </Col>
        </Row>
        <Row>
          <Col md="3">
          <h5 className="modal-title" id="detailpopoutLabel">
          POD
          </h5>
          </Col>
          <Col md="9">
          <h5 className="modal-title" id="detailpopoutLabel">
          : {this.state.pod}
          </h5>
          </Col>
        </Row>
        </Form>
     
        
          
        <Table style={{marginTop:20}} className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Consignment No.</th>
                      <th scope="col">Container No.</th>      
                      <th scope="col">Require Date</th>                  
                      <th scope="col">Closing Date</th>
                      <th scope="col">Type</th>
                      <th scope="col" />
                    </tr>
                  </thead>
         {this.renderdetaillistData()}
         </Table>
       
        
       </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>}</Modal>  

         <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailorderpopout}
          toggle={() => this.setState({detailorderpopout:false})}
        >
           {this.state.loadingmodalorder?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         class
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
         <div>
          <div className="modal-header">
          <h1 className="modal-title" id="detailorderpopoutLabel">
              ROT No: {this.state.rotnumbermodalordertab}
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailorderpopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Form>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
              Shipper/Consignee
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
              : {this.state.shipperorder}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Booking No
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.bookingnoorder}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            POL
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.polorder}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            POD
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.podorder}
            </h5>
            </Col>
          </Row>
         
          </Form>

          <Table style={{marginTop:20}} className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Consignment No.</th>
                        <th scope="col">Container No.</th>      
                        <th scope="col">Require Date</th>                  
                        <th scope="col">Closing Date</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Detail</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    
                      {this.renderdetaillistDataOrder()}
                      
                   
                  </Table>
          
          
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailorderpopout:false})}
            >
              Close
            </Button>
         
          </div>
          </div>
  }
        </Modal> 

         <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailprocesspopout}
          toggle={() => this.setState({detailprocesspopout:false})}
        >
          <div className="modal-header">
          <h1 className="modal-title" id="detailprocesspopoutlabel">
              Consignment No: {this.state.consignmentnoprocesstab} 
            </h1>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailprocesspopout:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10">
          <Form>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailprocesspopoutLabel">
              Container No
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailprocesspopoutLabel">
              : {this.state.containernoprocesstab}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Require Date
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.requiredateprocesstab}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Closing Date
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.closingdateprocesstab}
            </h5>
            </Col>
          </Row>
          <Row>
            <Col md="3">
            <h5 className="modal-title" id="detailpopoutLabel">
            Type
            </h5>
            </Col>
            <Col md="9">
            <h5 className="modal-title" id="detailpopoutLabel">
            : {this.state.typeprocesstab}
            </h5>
            </Col>
          </Row>
          </Form>
       
          <Container style={{marginTop:35}}>
              <Row>
                
                <Col md="1">
                <Row>
                <Button style={{alignSelf: 'stretch'}} color={this.state.color1} disabled size="lg" type="button">           
                  </Button>
                  </Row>
                  <Row>
                  <h5>Assigning Driver</h5>
                  </Row>
                </Col>
                <Col lg="1">
                  <h6>----</h6>
                </Col>
                <Col lg="1">
                <Row>
                <Button style={{alignSelf: 'stretch'}} color={this.state.color2} disabled size="lg" type="button">           
                  </Button>
                  </Row>
                  <Row>
                  <h5>Staging In</h5>
                  </Row>
                </Col>
                <Col lg="1">
                  <h6>----</h6>
                </Col>
                <Col lg="1">
                <Row>
                <Button style={{alignSelf: 'stretch'}} color={this.state.color3} disabled size="lg" type="button">
                                  
                  </Button>
                  </Row>
                  <Row>
                  <h5>On the way Truck In</h5>
                  </Row>
                </Col>
                <Col lg="1">
                  <h6>----</h6>
                </Col>
                <Col lg="1">
                <Row>
                <Button style={{alignSelf: 'stretch'}} color={this.state.color4} disabled size="lg" type="button">
                                  
                  </Button>
                  </Row>
                  <Row>
                  <h5>Staging Out</h5>
                  </Row>
                </Col>
                <Col lg="1">
                  <h6>----</h6>
                </Col>
                <Col lg="1">
                <Row>
                <Button style={{alignSelf: 'stretch'}} color={this.state.color5} disabled size="lg" type="button">
                                  
                  </Button>
                  </Row>
                  <Row>
                  <h5>On the way Truck Out</h5>
                  </Row>
                </Col>
                <Col lg="1">
                  <h6>----</h6>
                </Col>
                <Col lg="1">
                <Row>
                <Button style={{alignSelf: 'stretch'}} color={this.state.color6} disabled size="lg" type="button">
                                  
                  </Button>
                  </Row>
                  <Row>
                  <h5>Completed</h5>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailprocesspopout:false})}
            >
              Close
            </Button>
         
          </div>
        </Modal>             
     
        {/* <div>
            <div style={ {  display: 'block',  padding: '20px',  height: '60px',  width: '100%',}} />
            <div style={{backgroundColor: "white",    borderTop: "1px solid #E7E7E7",    textAlign: "center",    padding: "20px",
              position: "fixed",
              left: "0",
              bottom: "0",
              height: "60px",
              width: "100%",}}>
               <div className="d-md-inline-block ">
            
              <Button  color="default" type="button" >
                Dashboard
              </Button>
              

           
              <Button   color="white" type="button" >
              <i className="ni ni-planet mr-2" />
                 Quotation
              </Button>
             
          
              <Button color="white" type="button" >
              <i className="ni ni-check-bold mr-2" />
                 Orders
              </Button>
           
                </div>
          
           
            </div>
        </div> */}
      </>
    );
  }
}
class ComponentToPrint extends React.Component {
  render() {
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/consignote7.png")}
      />
     
      <span class="bottom-leftprint">
                centerasdfasdfasfdasfasdfasfafdasdfasfd1
              </span>
      

      </div>
     
       
    );
  }
}
export default Orders;
