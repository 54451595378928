/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{createContext} from "react";
import { Link } from 'react-router-dom';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col,NavItem,NavLink,Button,Nav } from "reactstrap";
import classnames from "classnames";



class Header extends React.Component {

  constructor(props) {
    super(props);
   
    this.state ={
      showaddnewbutton: false,
    }
    
  }

  
  render() {
    return (
      <>
      
       {window.location.pathname == "/admin/quotation" ?
        <div className="header bg-gradient-info pb-6 pt-5 pt-md-5">
          <Container fluid>
            <div className="header-body" style={{marginBottom:30}}>
           
         

          
            
           
            </div>
          </Container>
        </div>
        :window.location.pathname == "/admin/orders" ?
        <div className="header bg-gradient-info pb-6 pt-5 pt-md-5">
          <Container fluid>
            <div className="header-body">
           
            {/* <NavLink className="nav-link-icon"  to="/admin/addnewrot" tag={Link}>
            <Button  className="my-4" color="default" type="button" style={{marginLeft:-10}}>
              + Add New ROT
            </Button>
            </NavLink> */}

            </div>
          </Container>
        </div> : <div className="header bg-gradient-info pb-9 pt-5 pt-md-5">
        <Container fluid>
          <div className="header-body">

          </div>
        </Container>
      </div>
        
        
       }

 
      </>
    );
  }
}

export default Header;
