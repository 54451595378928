/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import ReactDatetime from "react-datetime";
import { textSpanIntersectsWith } from "typescript";

class AddNewROT extends React.Component {
  
  state = {
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatordropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
    quotationtabledatafinal:[],
    operatorlistdata:[],
    yardlist:[],
    typelist:[],
    Billtoparty:localStorage.getItem('Billtoparty'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    forwardingagentlist:[],
    aftersubmitstatus:0,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
   
  };

  handleChange(event,stateid) {
    if(stateid === "picvalue"){
      this.setState({picvalue: event});
    } else if(stateid == "contactvalue"){
      this.setState({contactvalue: event});
    }else if(stateid == "poltext"){
      this.setState({poltext: event});
    }else if(stateid == "podtext"){
      this.setState({podtext: event});
    }else if(stateid == "bookingno"){
      this.setState({bookingno: event});
    }else if(stateid == "volumenumberinput"){
      this.setState({volumenumberinput: event});
    }else if(stateid == "volumenumberinput1"){
      this.setState({volumenumberinput1: event});
    }else if(stateid == "volumenumberinput2"){
      this.setState({volumenumberinput2: event});
    }else if(stateid == "volumenumberinput3"){
      this.setState({volumenumberinput3: event});
    }else if(stateid == "volumenumberinput4"){
      this.setState({volumenumberinput4: event});
    }else if(stateid == "volumenumberinput5"){
      this.setState({volumenumberinput5: event});
    }else if(stateid == "volumenumberinput6"){
      this.setState({volumenumberinput6: event});
    }else if(stateid == "volumenumberinput7"){
      this.setState({volumenumberinput7: event});
    }else if(stateid == "volumenumberinput8"){
      this.setState({volumenumberinput8: event});
    }else if(stateid == "volumenumberinput9"){
      this.setState({volumenumberinput9: event});
    }


    
  }

  componentDidMount(){
    this.getdetailquotationtableapi();
    this.getoperatorlistapi();
    this.getpickupyardreturnyardlist();
    this.getforwardingagentlist();
  }

  //cleaning poltext 
  //plus minus button show hide
  submitnewrotforpending =() =>{
    console.log("clicked")
    this.setState({aftersubmitstatus:3})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper",this.state.areadropdowntext );
    urlencoded.append("deliveryaddress", this.state.deliveryaddress);
    urlencoded.append("areatextbox", this.state.areatextbox);
    urlencoded.append("picvalue", this.state.picvalue);
    urlencoded.append("contact", this.state.contactvalue);

    //shipment type problem
    //13894 become 13896 rot problem?
    urlencoded.append("shipmenttypedropdowntext", this.state.shipmenttypeadropdowntext);
    urlencoded.append("poltext", this.state.poltext);
    urlencoded.append("podtext", this.state.podtext);
    urlencoded.append("bookingno", this.state.bookingno);

    var vsletapassup = new Date(this.state.Vsleta).getTime()/1000;
    urlencoded.append("Vsleta", vsletapassup);

    var closingdaypasssup = new Date(this.state.closingday).getTime()/1000;
    urlencoded.append("closingday", closingdaypasssup);
    urlencoded.append("operatoreadropdowntext",this.state.operatoreadropdowntext );
    urlencoded.append("pickupdropdowntext", this.state.pickupdropdowntext);

    //till here, need to add volume and check volume key in problem
    if(this.state.volumenumberinput != 0) {
    urlencoded.append("volumenumberinput", this.state.volumenumberinput);
    urlencoded.append("type1dropdowntext",this.state.type1dropdowntext );
    }else{
      //alert
    }
    if(this.state.volumenumberinput1 != 0) {
      urlencoded.append("volumenumberinput1", this.state.volumenumberinput1);
      urlencoded.append("type1dropdowntext1", this.state.type1dropdowntext1);
    }
    if(this.state.volumenumberinput2 != 0 && this.state.rowvolume1 == true) {
      urlencoded.append("volumenumberinput2", this.state.volumenumberinput2);
      urlencoded.append("type1dropdowntext2",this.state.type1dropdowntext2 );
    }
    if(this.state.volumenumberinput3 != 0 && this.state.rowvolume2 == true) {
      urlencoded.append("volumenumberinput3", this.state.volumenumberinput3);
      urlencoded.append("type1dropdowntext3",this.state.type1dropdowntext3 );
    }
    if(this.state.volumenumberinput4 != 0 && this.state.rowvolume3 == true) {
      urlencoded.append("volumenumberinput4", this.state.volumenumberinput4);
      urlencoded.append("type1dropdowntext4",this.state.type1dropdowntext4 );
    }
    if(this.state.volumenumberinput5 != 0 && this.state.rowvolume4 == true) {
      urlencoded.append("volumenumberinput5", this.state.volumenumberinput5);
      urlencoded.append("type1dropdowntext5",this.state.type1dropdowntext5 );
    }
    if(this.state.volumenumberinput6 != 0 && this.state.rowvolume5 == true) {
      urlencoded.append("volumenumberinput6", this.state.volumenumberinput6);
      urlencoded.append("type1dropdowntext6",this.state.type1dropdowntext6 );
    }
    if(this.state.volumenumberinput7 != 0 && this.state.rowvolume6 == true) {
      urlencoded.append("volumenumberinput7", this.state.volumenumberinput7);
      urlencoded.append("type1dropdowntext7",this.state.type1dropdowntext7 );
    }
    if(this.state.volumenumberinput8 != 0 && this.state.rowvolume7 == true) {
      urlencoded.append("volumenumberinput8", this.state.volumenumberinput8);
      urlencoded.append("type1dropdowntext8",this.state.type1dropdowntext8 );
    }
    if(this.state.volumenumberinput9 != 0 && this.state.rowvolume8 == true) {
      urlencoded.append("volumenumberinput9", this.state.volumenumberinput9);
      urlencoded.append("type1dropdowntext9",this.state.type1dropdowntext9 );
    }
  
    
    let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
    Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
    Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
    Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
    Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
    
    urlencoded.append("totalnumberofjob", numberrow);
    for (let i=0;i<numberrow;i++){
      urlencoded.append("consigment"+i, i);
      urlencoded.append("container"+i, this.state["container" +i]);
      urlencoded.append("Seal"+i, this.state["Seal" +i]);
      urlencoded.append("requestdate"+i, this.state["requestdate" +i]);

      console.log(i);
      console.log(this.state["container" +i]);
      console.log(this.state["Seal" +i]);
      console.log(this.state["requestdate" +i]);
    }
    
    urlencoded.append("vesselname", this.state.vesselname);
    urlencoded.append("vesselvoyage", this.state.vesselvoyage);
    urlencoded.append("commodity", this.state.commodity);
    urlencoded.append("forwardingagentdropdowntext", this.state.forwardingagentdropdowntext);
    urlencoded.append("remark", this.state.remark);

    //file upload

    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-addnewrotapi'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //success
            this.setState({aftersubmitstatus:1})

            //console.log(json.user)
            //  var lengthemployee = Object.keys(json.user).length;
            //  for(let i=0;i<lengthemployee;i++){

            //   //status need do
            //    this.setState({rotpendinglist:[...this.state.rotpendinglist,
            //     { no:i+1,
            //       rotno:json.user[i].Rotnumber,
            //       shippername:json.user[i].Shipper,
            //       totalvolume:json.user[i].totalvolume,
            //       bookingno:json.user[i].Bookingno,
            //       status:1,
            //       remark:json.user[i].Remark,
            //     },            
            //   ]})
              
              
             
            //    console.log(this.state.rotpendinglist)
             

             
            //   }
              this.setState({loading:false})  
            }        
               
         else{
           //fail submit
          this.setState({aftersubmitstatus:2})
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  getdetailquotationtableapi =()=>{
    this.setState({loading:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getquotationlistfinal'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  area:json.user[i].Area,
                  address:json.user[i].Address,
                },            
              ]})
              
              
             
               //console.log(this.state.quotationtabledatafinal)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })

    
       

   
  }

  getoperatorlistapi =() =>{
    this.setState({loading:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getoperatorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({operatorlistdata:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({operatorlistdata:[...this.state.operatorlistdata,
                { no:i+1,
                  operator:json.user[i].Operator,
                },            
              ]})
               console.log(this.state.operatorlistdata)
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }
  
  getpickupyardreturnyardlist =()=>{
    this.setState({loading:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getpickupyardreturnyardlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        
            console.log(json)
            this.setState({yardlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({yardlist:[...this.state.yardlist,
                { no:i+1,
                  area:json.user[i].Area,
                },            
              ]})
               console.log(this.state.yardlist)
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getcontainertypelist =(pguorptp,shippername)=>{
    this.setState({loading:true})
    this.setState({typelist:[]});  
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper", shippername);
    if(this.state.shipmenttypeadropdowntext == 'Export'){
      if(pguorptp == 'Pasir Gudang'){
        urlencoded.append("Pguorptp", 'PGU');
      }else if(pguorptp == 'Tanjung Pelepas'){
        urlencoded.append("Pguorptp", 'PTP');
      }
    }else if(this.state.shipmenttypeadropdowntext == 'Import'){
      if(pguorptp == 'Pasir Gudang'){
        urlencoded.append("Pguorptp", 'PGU');
      }else if(pguorptp == 'Tanjung Pelepas'){
        urlencoded.append("Pguorptp", 'PTP');
      }
    }
    console.log(this.state.Billtoparty)
    console.log(shippername)
    console.log(pguorptp)

    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getcontainertypelist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        
            console.log(json)
            this.setState({typelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({typelist:[...this.state.typelist,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                },            
              ]})
               console.log(this.state.typelist)
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getforwardingagentlist = ()=>{
    this.setState({loading:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getforwardingagentlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({forwardingagentlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({forwardingagentlist:[...this.state.forwardingagentlist,
                { no:i+1,
                  forwardingagent:json.user[i].companyname,
                },            
              ]})
               console.log(this.state.forwardingagentlist)
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }


  functionselectshipmenttype =(shippername,area,address)=>{
    this.setState({
      areadropdowntext:shippername,
      deliveryaddress:address,
      areatextbox:area,
      
    })
    console.log(shippername)
    console.log(this.state.poltext)
    console.log(this.state.podtext)
    if(this.state.shipmenttypeadropdowntext =='Export'){
     this.getcontainertypelist(this.state.poltext,shippername)
    } else if(this.state.shipmenttypeadropdowntext =='Import'){
     this.getcontainertypelist(this.state.podtext,shippername)
    } else{

    }
  }
  rendershipperdropdownmenu() {
   

    return this.state.quotationtabledatafinal.map((data, index) => {
       const {address,area,shippername} = data //destructuring
      
       return (
        <DropdownItem onClick={this.functionselectshipmenttype.bind(this,shippername,area,address)}>{shippername}</DropdownItem>
       )
    })
 }
 renderoperatordropdownmenu() {
      

  return this.state.operatorlistdata.map((data, index) => {
     const {operator} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        operatoreadropdowntext:operator,
      })}>{operator}</DropdownItem>
     )
  })
}
 renderconsignmentnorow(totalrownumber) {

    
     return (
      <Row>

      <Col md="3">
        
          <FormGroup>
          <Input
              id="Consignmentnoinput2"
              placeholder="Consignment No"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md="3">

          <FormGroup>
          <Input
              id="Containernoinput2"
              placeholder="Container No"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
          <Input
              id="Sealnoinput2"
              placeholder="Seal No"
              type="text"
            />
          </FormGroup>
          </Col>
          <Col md="3">
          <FormGroup>
          <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Request Date"
                    }}
                    timeFormat={false}
                  />
                </InputGroup>
          </FormGroup>
          </Col>
      </Row>
     )
  
}
renderforwardingagentrow() {
      

  return this.state.forwardingagentlist.map((data, index) => {
     const {forwardingagent} = data //destructuring
     return (

      <DropdownItem onClick={() => this.setState({
        forwardingagentdropdowntext:forwardingagent,
      })}>{forwardingagent}</DropdownItem>
     )
  })
}
renderaddinvolumerow(){
  let consignmentnorow=[]
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
                  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
                  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
                  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
                  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

  for(let i=0;i<numberrow;i++){
    var numbershow = i+1
    consignmentnorow.push(
      <Row>

      <Col md="3">
        
          <FormGroup>
          <Input
              id="Consignmentnoinput2"
              placeholder="Consignment No"
              type="text"
              disabled
              value= {"No. " + numbershow}
              
            />
          </FormGroup>
        </Col>
        <Col md="3">

          <FormGroup>
          <Input
              id="Containernoinput2"
              placeholder="Container No"
              value={this.state["container" + i]}
              onChange = {(event) => this.setState({ ["container" + i]: event.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
          <Input
              id="Sealnoinput2"
              placeholder="Seal No"
              type="text"
              value={this.state["Seal" + i]}
              onChange = {(e) => this.setState({ ["Seal" + i]: e.target.value })}
            />
          </FormGroup>
          </Col>
          <Col md="3">
          <FormGroup>
          <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Request Date"
                    }}
                    timeFormat={false}
                    onChange = {(e) => this.setState({ ["requestdate" + i]: e })}
                  />
                </InputGroup>
          </FormGroup>
          </Col>
      </Row>
    )  
  }
    return consignmentnorow
    
}
  
renderyardlistdropdownmenu() {
  return this.state.yardlist.map((data, index) => {
     const {area} = data //destructuring
     //console.log(area)
     return (
      <DropdownItem onClick={() => this.setState({
        pickupdropdowntext:area,
      })}>{area}</DropdownItem>
     )
  })
}

rendertypelistdropdownmenu() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}

rendertypelistdropdownmenu1() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext1:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu2() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext2:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu3() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext3:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu4() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext4:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu5() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext5:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu6() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext6:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu7() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext7:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu8() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext8:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}
rendertypelistdropdownmenu9() {
      
  return this.state.typelist.map((data, index) => {
     const {size,type} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        type1dropdowntext9:(size+' '+type),
      })}>{size+' '+type}</DropdownItem>
     )
  })
}

renderreturnyardlistdropdownmenu() {
  return this.state.yardlist.map((data, index) => {
     const {area} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        returnyarddropdowntext:area,
      })}>{area}</DropdownItem>
     )
  })
}

callgettypelistfunctionpol = (pguorptp) =>{
  this.setState({poltext:pguorptp})
  this.getcontainertypelist(pguorptp,this.state.areadropdowntext);
}

callgettypelistfunctionpod = (pguorptp) =>{
  this.setState({podtext:pguorptp})
  this.getcontainertypelist(pguorptp,this.state.areadropdowntext);
}



addvolumebuttonclicked = () =>{
  if(this.state.volumeshowrow >= 8){
    
  }else{
    this.setState({volumeshowrow:this.state.volumeshowrow+1})
  }
  
  var numbershowtemp = this.state.volumeshowrow+1
  console.log(numbershowtemp)
      if(numbershowtemp == 0){
        this.setState({
          rowvolume1:false,
          rowvolume2:false,
          rowvolume3:false,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:false,
        })
      } else if(numbershowtemp == 1){
        this.setState({
          rowvolume1:true,
          rowvolume2:false,
          rowvolume3:false,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      } else if(numbershowtemp == 2){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:false,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      } else if(numbershowtemp == 3){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      } else if(numbershowtemp == 4){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp == 5){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp ==6){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:true,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp == 7){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:true,
          rowvolume7:true,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp == 8){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:true,
          rowvolume7:true,
          rowvolume8:true,
          minusbuttonshow:true,
        })
      }
}

minusvolumebuttonclicked = () =>{
  if(this.state.volumeshowrow <= 0){
  }else{
    this.setState({volumeshowrow:this.state.volumeshowrow-1})
  }

  var numbershowtemp = this.state.volumeshowrow-1
  console.log(numbershowtemp)
      if(numbershowtemp == 0){
        this.setState({
          rowvolume1:false,
          rowvolume2:false,
          rowvolume3:false,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:false,
        })
      } else if(numbershowtemp == 1){
        this.setState({
          rowvolume1:true,
          rowvolume2:false,
          rowvolume3:false,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      } else if(numbershowtemp == 2){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:false,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      } else if(numbershowtemp == 3){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:false,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      } else if(numbershowtemp == 4){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:false,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp == 5){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:false,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp ==6){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:true,
          rowvolume7:false,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp == 7){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:true,
          rowvolume7:true,
          rowvolume8:false,
          minusbuttonshow:true,
        })
      }else if(numbershowtemp == 8){
        this.setState({
          rowvolume1:true,
          rowvolume2:true,
          rowvolume3:true,
          rowvolume4:true,
          rowvolume5:true,
          rowvolume6:true,
          rowvolume7:true,
          rowvolume8:true,
          minusbuttonshow:true,
        })
      }
}

  render() {
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Create New ROT:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
                
              <CardBody>
                <div className="modal-body p-10">
                <Form>
                  {/*Bill to Party*/}
                  <Row>
                    <Col md="12">
                      <p className="h5">Bill To Party:</p>
                      <FormGroup>
                        <Input
                          id="Billtopartyinput1"
                          placeholder="Bill To Party"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  {/*Shipper*/}
                  <Row>
                  <Col md="12">
                      <p className="h5">Shipper/Consignee:</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownarea} toggle={() => this.toggledropdownarea("isopendropdownarea")}>
                        <DropdownToggle className = "btn-block" block={true} caret>
                          {this.state.areadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.rendershipperdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  {/*Address*/}
                  <Row>
                  <Col md="12">
                      <p className="h5">Address:</p>
                      <FormGroup>
                      <Input
                          id="Addressinput1"
                          placeholder="Address"
                          type="text"
                          value={this.state.deliveryaddress}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  
                  {/*Area,PIC,Contact*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Area:</p>
                      <FormGroup>
                      <Input
                          id="areainput1"
                          placeholder="Area"
                          type="text"
                          value={this.state.areatextbox}
                          disabled
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">PIC:</p>
                      <FormGroup>
                      <Input
                          id="Picinput1"
                          placeholder="PIC"
                          type="text"
                          value={this.state.picvalue}
                          onChange={(e) => this.handleChange(e.target.value,"picvalue")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Contact:</p>
                      <FormGroup>
                      <Input
                          id="Contactinput1"
                          placeholder="Contact"
                          type="text"
                          onChange={(e) => this.handleChange(e.target.value,"contactvalue")}
                          value={this.state.contactvalue}
                        />
                      </FormGroup>
                    </Col>
                
                  </Row>
                  {/*ShipmentType,POL,POD*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Shipment Type:</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownshipmenttype} toggle={() => this.toggledropdownarea("isopendropdownshipmenttype")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.shipmenttypeadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>

                          <DropdownItem onClick={() => this.setState({shipmenttypeadropdowntext:'Export',
                        disableddropdownpol:false,
                        disableddropdownpod:false,})}>Export</DropdownItem>
                          <DropdownItem onClick={() => this.setState({shipmenttypeadropdowntext:'Import',
                        disableddropdownpol:false,
                        disableddropdownpod:false,})}>Import</DropdownItem>
                
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>

                    {this.state.shipmenttypeadropdowntext=='Import'?
                    
                      <Col md="4">
                      <p className="h5">POL:</p>
                      <FormGroup>
                      <Input
                          id="Pol1"
                          placeholder="POL"
                          type="text"
                          value={this.state.poltext}
                          onChange={(e) => this.handleChange(e.target.value,"poltext")}
                          disabled={this.state.disableddropdownpol}
                        />
                      </FormGroup>
                    </Col>
         
                    :
                    <Col md="4">
                    <p className="h5">POL:</p>
                    <FormGroup>
                    <Dropdown size="lg" disabled className = "btn-block" block={true} isOpen={this.state.isopendropdownpol} toggle={() => this.toggledropdownarea("isopendropdownpol")}>
                      <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.poltext}
                        </DropdownToggle>
                      
                        {this.state.disableddropdownpod?
                          null
                        :
                        <DropdownMenu>
                        <DropdownItem onClick={this.callgettypelistfunctionpol.bind(this,"Pasir Gudang")}>Pasir Gudang</DropdownItem>
                        <DropdownItem onClick={this.callgettypelistfunctionpol.bind(this,"Tanjung Pelepas")}>Tanjung Pelepas</DropdownItem>
                        </DropdownMenu>
                        }
                        
                      
                    </Dropdown>
                    </FormGroup>
                  </Col>
                
                    }
              {this.state.shipmenttypeadropdowntext=='Import'?
                    
                  <Col md="4">
                     <p className="h5">POD:</p>
                     <FormGroup>
                     <Dropdown size="lg" disabled  className = "btn-block" block={true} isOpen={this.state.isopendropdownpod} toggle={() => this.toggledropdownarea("isopendropdownpod")}>
                       <DropdownToggle caret className = "btn-block" block={true}>
                         {this.state.podtext}
                         </DropdownToggle>

                         {this.state.disableddropdownpod?
                          null
                        :
                        <DropdownMenu>
                        <DropdownItem onClick={this.callgettypelistfunctionpod.bind(this,"Pasir Gudang")}>Pasir Gudang</DropdownItem>
                        <DropdownItem onClick={this.callgettypelistfunctionpod.bind(this,"Tanjung Pelepas")}>Tanjung Pelepas</DropdownItem>
              
                      </DropdownMenu>
                        }
                     
                     </Dropdown>
                     </FormGroup>
                   </Col>
                 
                  :
                   <Col md="4">
                   <p className="h5">POD:</p>
                   <FormGroup>
                   <Input
                       id="Podinput1"
                       placeholder="POD"
                       type="text"
                       value={this.state.podtext}
                       disabled={this.state.disableddropdownpod}
                       onChange={(e) => this.handleChange(e.target.value,"podtext")}
                     />
                   </FormGroup>
                   </Col>
                  }
                  </Row>
                  {/*Bookingno,VslETA,ClosingDay*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Booking/BL No:</p>
                      <FormGroup>
                      <Input
                          id="Bookingnumberinput1"
                          placeholder="Booking No"
                          type="text"
                          value={this.state.bookingno}
                          onChange={(e) => this.handleChange(e.target.value,"bookingno")}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Vsl Eta:</p>
                      <FormGroup>
                      <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Vsl Eta",
                      

                      }}
                      onChange={e => this.setState({ Vsleta: e })}
                      timeFormat={false}
                    />
                  </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Closing/Last Day:</p>
                      <FormGroup>
                      <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Closing/Last Day"
                      }}
                      timeFormat={false}
                      onChange={e => this.setState({ closingday: e })}
                    />
                  </InputGroup>
                      </FormGroup>
                      </Col>
                  </Row>
                  {/*Operator,Pickup*/}
                  {this.state.shipmenttypeadropdowntext=='Export'?
                  <Row>
                  <Col md="6">
                      <p className="h5">Operator:</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownoperator} toggle={() => this.toggledropdownarea("isopendropdownoperator")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.operatoreadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.renderoperatordropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Pick Up</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownpickup} toggle={() => this.toggledropdownarea("isopendropdownpickup")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.pickupdropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                         {this.renderyardlistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                    :
                  <Row>
                  <Col md="4">
                      <p className="h5">Operator:</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownoperator} toggle={() => this.toggledropdownarea("isopendropdownoperator")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.operatoreadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                          {this.renderoperatordropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Pick Up</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownpickup} toggle={() => this.toggledropdownarea("isopendropdownpickup")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.pickupdropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.renderyardlistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Return Yard</p>
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownreturnyard} toggle={() => this.toggledropdownarea("isopendropdownreturnyard")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.returnyarddropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.renderreturnyardlistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>

                  </Row>
                  }
 
                  {/*Volume*/}
                  <Row>
                    <Col md="12">
                    <p className="h5">Volume: (If the volume type not contain, please add at Quotation)</p>
                    </Col>
                  </Row>
                 

                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                          name="volumenumberinput"
                          id="volumenumberinput"
                          placeholder="Volume"
                          type="number"
                          min="0"
                          value={this.state.volumenumberinput}
                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput")}/>
                        
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype} toggle={() => this.toggledropdownarea("isopendropdowntype")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.type1dropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                          {this.rendertypelistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>


                    
                  </Row>               
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                           name="volumenumberinput1"
                           id="volumenumberinput1"
                           placeholder="Volume"
                           type="number"
                           min="0"
                           value={this.state.volumenumberinput1}
                           onChange={(e) => this.handleChange(e.target.value,"volumenumberinput1")}/>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype1} toggle={() => this.toggledropdownarea("isopendropdowntype1")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.type1dropdowntext1}
                          </DropdownToggle>
                        <DropdownMenu>
                            {this.rendertypelistdropdownmenu1()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.rowvolume1?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                          name="volumenumberinput2"
                          id="volumenumberinput2"
                          placeholder="Volume"
                          type="number"
                          min="0"
                          value={this.state.volumenumberinput2}
                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput2")}/>
                        
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype2} toggle={() => this.toggledropdownarea("isopendropdowntype2")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.type1dropdowntext2}
                          </DropdownToggle>
                        <DropdownMenu>
                          {this.rendertypelistdropdownmenu2()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>


                    
                  </Row>               
                  :
                  null
                  }

                  {this.state.rowvolume2?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                            name="volumenumberinput3"
                            id="volumenumberinput3"
                            placeholder="Volume"
                            type="number"
                            min="0"
                            value={this.state.volumenumberinput3}
                            onChange={(e) => this.handleChange(e.target.value,"volumenumberinput3")}/>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype3} toggle={() => this.toggledropdownarea("isopendropdowntype3")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.type1dropdowntext3}
                          </DropdownToggle>
                        <DropdownMenu>
                            {this.rendertypelistdropdownmenu3()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                  :
                  null
                    }

                  {this.state.rowvolume3?
                        <Row>
                        <Col md="3">
                            <FormGroup>
                            <Input
                                name="volumenumberinput4"
                                id="volumenumberinput4"
                                placeholder="Volume"
                                type="number"
                                min="0"
                                value={this.state.volumenumberinput4}
                                onChange={(e) => this.handleChange(e.target.value,"volumenumberinput4")}/>
                              
                            </FormGroup>
                          </Col>
                          <Col md="9">
                            <FormGroup>
                            <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype4} toggle={() => this.toggledropdownarea("isopendropdowntype4")}>
                              <DropdownToggle caret className = "btn-block" block={true}>
                                {this.state.type1dropdowntext4}
                                </DropdownToggle>
                              <DropdownMenu>
                                {this.rendertypelistdropdownmenu4()}
                              </DropdownMenu>
                            </Dropdown>
                            </FormGroup>
                          </Col>


                          
                        </Row>               
                  :
                  null
                    }

                  {this.state.rowvolume4?
           <Row>
           <Col md="3">
               <FormGroup>
               <Input
                    name="volumenumberinput5"
                    id="volumenumberinput5"
                    placeholder="Volume"
                    type="number"
                    min="0"
                    value={this.state.volumenumberinput5}
                    onChange={(e) => this.handleChange(e.target.value,"volumenumberinput5")}/>
               </FormGroup>
             </Col>
             <Col md="9">
               <FormGroup>
               <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype5} toggle={() => this.toggledropdownarea("isopendropdowntype5")}>
                 <DropdownToggle caret className = "btn-block" block={true}>
                 {this.state.type1dropdowntext5}
                   </DropdownToggle>
                 <DropdownMenu>
                     {this.rendertypelistdropdownmenu5()}
                 </DropdownMenu>
               </Dropdown>
               </FormGroup>
             </Col>
           </Row>
                  :
                  null
                    }

                {this.state.rowvolume5?
                  <Row>
                                  <Col md="3">
                                      <FormGroup>
                                      <Input
                                          name="volumenumberinput6"
                                          id="volumenumberinput6"
                                          placeholder="Volume"
                                          type="number"
                                          min="0"
                                          value={this.state.volumenumberinput6}
                                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput6")}/>
                                        
                                      </FormGroup>
                                    </Col>
                                    <Col md="9">
                                      <FormGroup>
                                      <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype6} toggle={() => this.toggledropdownarea("isopendropdowntype6")}>
                                        <DropdownToggle caret className = "btn-block" block={true}>
                                          {this.state.type1dropdowntext6}
                                          </DropdownToggle>
                                        <DropdownMenu>
                                          {this.rendertypelistdropdownmenu6()}
                                        </DropdownMenu>
                                      </Dropdown>
                                      </FormGroup>
                                    </Col>


                                    
                                  </Row>                         
                :
                null
                  }

                {this.state.rowvolume6?
                <Row>
                <Col md="3">
                    <FormGroup>
                    <Input
                         name="volumenumberinput7"
                         id="volumenumberinput7"
                         placeholder="Volume"
                         type="number"
                         min="0"
                         value={this.state.volumenumberinput7}
                         onChange={(e) => this.handleChange(e.target.value,"volumenumberinput7")}/>
                    </FormGroup>
                  </Col>
                  <Col md="9">
                    <FormGroup>
                    <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype7} toggle={() => this.toggledropdownarea("isopendropdowntype7")}>
                      <DropdownToggle caret className = "btn-block" block={true}>
                      {this.state.type1dropdowntext7}
                        </DropdownToggle>
                      <DropdownMenu>
                          {this.rendertypelistdropdownmenu7()}
                      </DropdownMenu>
                    </Dropdown>
                    </FormGroup>
                  </Col>
                </Row>
                :
                null
                }
                {this.state.rowvolume7?
                <Row>
                <Col md="3">
                    <FormGroup>
                    <Input
                        name="volumenumberinput8"
                        id="volumenumberinput8"
                        placeholder="Volume"
                        type="number"
                        min="0"
                        value={this.state.volumenumberinput8}
                        onChange={(e) => this.handleChange(e.target.value,"volumenumberinput8")}/>
                      
                    </FormGroup>
                  </Col>
                  <Col md="9">
                    <FormGroup>
                    <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype8} toggle={() => this.toggledropdownarea("isopendropdowntype8")}>
                      <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.type1dropdowntext8}
                        </DropdownToggle>
                      <DropdownMenu>
                        {this.rendertypelistdropdownmenu8()}
                      </DropdownMenu>
                    </Dropdown>
                    </FormGroup>
                  </Col>


                  
                </Row>               
                :
                null
                }
                {this.state.rowvolume8?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                           name="volumenumberinput9"
                           id="volumenumberinput9"
                           placeholder="Volume"
                           type="number"
                           min="0"
                           value={this.state.volumenumberinput9}
                           onChange={(e) => this.handleChange(e.target.value,"volumenumberinput9")}/>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype9} toggle={() => this.toggledropdownarea("isopendropdowntype9")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.type1dropdowntext9}
                          </DropdownToggle>
                        <DropdownMenu>
                            {this.rendertypelistdropdownmenu9()}
                        </DropdownMenu>
                      </Dropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                :
                null
                }


                  <Row>
                    <Col md="12">
                    <Button color="primary" type="button" onClick={this.addvolumebuttonclicked.bind(this)}>
                          +
                      </Button>
                      <Button color="primary" type="button" onClick={this.minusvolumebuttonclicked.bind(this)}>
                          -
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  {/*ConsignmentnoRow*/}
                  
                  <Row>
                    <Col md="3">
                        <p className="h5">Consignment No:</p>
                        </Col>
                      <Col md="3">
                        <p className="h5">Container No:</p>
                          </Col>
                      <Col md="3">
                        <p className="h5">Seal No:</p>
                          </Col>
                        <Col md="3">
                        <p className="h5">Request Date:</p>
                          </Col>
                    </Row>
                    {this.renderaddinvolumerow()}

                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                   
                  
                  {/*Vessel*/}
                  <Row>
                  <Col md="6">
                      <p className="h5">Vessel Name:</p>
                      <FormGroup>
                      <Input
                          id="Vesselnameinput1"
                          placeholder="Vessel Name"
                          type="text"
                          value={this.state.vesselname}
                          onChange={(e)=>this.setState({vesselname:e.target.value})}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Vessel Voyage:</p>
                      <FormGroup>
                      <Input
                          id="Vesselvoyageinput1"
                          placeholder="Vessel Voyage"
                          type="text"
                          value={this.state.vesselvoyage}
                          onChange={(e)=>this.setState({vesselvoyage:e.target.value})}
                        />
                      </FormGroup>
                    </Col>



            </Row>
                  
                  {/*Commodity*/}
                  <Row>
            <Col md="6">
                <p className="h5">Commodity:</p>
                <FormGroup>
                <Input
                    id="Commodityinput1"
                    placeholder="Commodity"
                    type="text"
                    value={this.state.commodity}
                    onChange={(e)=>this.setState({commodity:e.target.value})}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <p className="h5">Forwarding Agent:</p>
                <FormGroup>
                <Dropdown className = "btn-block" block={true} isOpen={this.state.isopendropdownforwardingagent} toggle={() => this.toggledropdownarea("isopendropdownforwardingagent")}>
                  <DropdownToggle  caret   className = "btn-block" block={true}>
                    {this.state.forwardingagentdropdowntext}
                    </DropdownToggle>
                  <DropdownMenu >
                    {this.renderforwardingagentrow()}
                  </DropdownMenu>
                </Dropdown>
                </FormGroup>
              </Col>



            </Row>
                  
                  {/*Remark*/}
                  <Row>
            <Col md="12">
                <p className="h5">Remark:</p>
                <FormGroup>
                <Input
                    id="Remarkinput1"
                    placeholder="Remark"
                    rows="5"
                    type="textarea"
                    value={this.state.remark}
                    onChange={(e)=>this.setState({remark:e.target.value})}
                  />
                </FormGroup>
              </Col>
            
            </Row>
                  
                  {/*Attached button*/}
                  <Row>
                  <Input
              id="fileupload"
              placeholder="Attached file122"
              className="fileuploadcss"
              multiple
              data-multiple-caption="1"
              type="file"
            />
          
            </Row>
          
        </Form>
          </div>




          <div className="modal-footer">
      
            <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending()}>
              Submit
            </Button>
          </div>
           
          </CardBody>
                
              </Card>
            </div>
          </Row>
        </Container>
     
        
      

     
       
      </>
    );
  }
}


export default AddNewROT;


