/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";

class Profile extends React.Component {
  state = {
    isopendropdowndirect:false,
    directdropdowntext:'Forwarder',
    name:localStorage.getItem('Name'),
    ref:window.localStorage.getItem('Ref'),
    profileimageurl:localStorage.getItem('Profilepictureurl'),
    Level:localStorage.getItem('Level'),
    Billtoparty:window.localStorage.getItem('Billtoparty'),
    Directorforwarder:localStorage.getItem('Directorforwarder'),
    Email:window.localStorage.getItem('Email'),
    Contact:localStorage.getItem('Contact'),
    usernameinput:localStorage.getItem('Username'),
  };
 
  
  toggledropdowndirect =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };
  
  componentDidMount(){
    if(this.state.Directorforwarder == 1){
      this.setState({directdropdowntext:'Forwarder'})
    }else{
      this.setState({directdropdowntext:'Direct Customer'})
    }
  }

  render() {
    const imgurl = "http://reacttest.winnercloudstudio.com/img/profile_img/" + this.state.profileimageurl

   


    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={imgurl}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                   
                  </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading"></span>
                          <span className="description"></span>
                        </div>
                        <div>
                          <span className="heading"></span>
                          <span className="description"></span>
                        </div>
                        <div>
                          <span className="heading"></span>
                          <span className="description"></span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {this.state.name}
                      
                    </h3>
                  
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {this.state.Level}
                    </div>
                    
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                   
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User Information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                        <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.usernameinput} placeholder="Username" type="text" />
                  </InputGroup>
                </FormGroup>
               
            
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.Billtoparty} placeholder="Bill To Party" type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
              <Dropdown className = "btn-block" block={true} size="lg"  isOpen={this.state.isopendropdowndirect} toggle={() => this.toggledropdowndirect("isopendropdowndirect")}>
                <DropdownToggle caret className = "btn-block" block={true}  >
                  {this.state.directdropdowntext}
                  </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.setState({directdropdowntext:'Forwarder'})}>Forwarder</DropdownItem>
                  <DropdownItem onClick={() => this.setState({directdropdowntext:'Direct Customer'})}>Direct Customer</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.name} placeholder="PIC" type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.Email} placeholder="Email" type="email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.Contact} placeholder="Contact" type="text" />
                  </InputGroup>
                </FormGroup>
                   </Col>
                   </Row>
                   
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
