/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import loadinggif from './../../assets/gif/lorrygif.gif'
import ReactToPrint from 'react-to-print';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
class Quotation extends React.Component {
  
  state = {
    loading:false,
    loadingmodal:false,
    tabs: 2,
    exampleModal: false,
    editpopuout:false,
    Billtoparty:localStorage.getItem('Billtoparty'),
    quotationtabledata:[],
    quotationtabledatafinal:[],
    detailbilltoparty:'',
    detailshipper:'',
    detailaddress:'',
    detailarea:'',
    detailpic:'',
    detaildepo:'',
    detailcontact:'',
    detailbacktobackcharge:'',
    pguprice20gp:'',
    pguprice20rf:'',
    pguprice20isotank:'',
    pguprice20ot:'',
    pguprice20tipper:'',
    pguprice40gp:'',
    pguprice40rf:'',
    pguprice40isotank:'',
    pguprice40tipper:'',
    
    ptpprice20gp:'',
    ptpprice20rf:'',
    ptpprice20isotank:'',
    ptpprice20ot:'',
    ptpprice20tipper:'',
    ptpprice40gp:'',
    ptpprice40rf:'',
    ptpprice40isotank:'',
    ptpprice40tipper:'',



    editbilltoparty:'',
    editshipper:'',
    editaddress:'',
    editarea:'',
    editpic:'',
    editdepo:'',
    editcontact:'',
    editbacktobackcharge:'',
    editpguprice20gp:'',
    editpguprice20rf:'',
    editpguprice20isotank:'',
    editpguprice20ot:'',
    editpguprice20tipper:'',
    editpguprice40gp:'',
    editpguprice40rf:'',
    editpguprice40isotank:'',
    editpguprice40tipper:'',
    
    editptpprice20gp:'',
    editptpprice20rf:'',
    editptpprice20isotank:'',
    editptpprice20ot:'',
    editptpprice20tipper:'',
    editptpprice40gp:'',
    editptpprice40rf:'',
    editptpprice40isotank:'',
    editptpprice40tipper:'',
    buttoncolor1:'white',
    buttoncolor2:'priamry'
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    if(index == 2){
      this.quotationbuttonclick();
    }else if(index ==1){
      this.quotationbuttonclickpending();
    }
    this.setState({
      [state]: index
    });
    
      
  
  };
 
  toggleModal = (state,shipperclicked,quotationnumber) => {
    if(state === "exampleModal"){
      console.log("trigger function detail")
      console.log(quotationnumber)
      this.quotationdetailfinalclick(shipperclicked,quotationnumber);
    }else if(state==="editpopuout"){
      console.log("trigger function editpopuout")
      this.quotationdetailfinalclick(shipperclicked,quotationnumber);
    }
    
    this.setState({
      [state]: !this.state[state]
    });
  };

  handleChange(event,stateid) {
    if(stateid === "detailbilltoparty"){
      this.setState({detailbilltoparty: event});
    } else if(stateid == "detailshipper"){
      this.setState({detailshipper: event});
    }else if(stateid == "detailaddress"){
      this.setState({detailaddress: event});
    }else if(stateid == "detailarea"){
      this.setState({detailarea: event});
    }else if(stateid == "detailpic"){
      this.setState({detailpic: event});
    }else if(stateid == "detailcontact"){
      this.setState({detailcontact: event});
    }else if(stateid == "pguprice20gp"){
      this.setState({pguprice20gp: event});
    }else if(stateid == "pguprice20isotank"){
      this.setState({pguprice20isotank: event});
    }else if(stateid == "pguprice20ot"){
      this.setState({pguprice20ot: event});
    }else if(stateid == "pguprice20rf"){
      this.setState({pguprice20rf: event});
    }else if(stateid == "pguprice20tipper"){
      this.setState({pguprice20tipper: event});
    }else if(stateid == "pguprice40gp"){
      this.setState({pguprice40gp: event});
    }else if(stateid == "pguprice40isotank"){
      this.setState({pguprice40isotank: event});
    }else if(stateid == "pguprice40rf"){
      this.setState({pguprice40rf: event});
    }else if(stateid == "pguprice40tipper"){
      this.setState({pguprice40tipper: event});
    }else if(stateid == "ptpprice20gp"){
      this.setState({ptpprice20gp: event});
    }else if(stateid == "ptpprice20isotank"){
      this.setState({ptpprice20isotank: event});
    }else if(stateid == "ptpprice20ot"){
      this.setState({ptpprice20ot: event});
    }else if(stateid == "ptpprice20rf"){
      this.setState({ptpprice20rf: event});
    }else if(stateid == "ptpprice20tipper"){
      this.setState({ptpprice20tipper: event});
    }else if(stateid == "ptpprice40gp"){
      this.setState({ptpprice40gp: event});
    }else if(stateid == "ptpprice40isotank"){
      this.setState({ptpprice40isotank: event});
    }else if(stateid == "ptpprice40tipper"){
      this.setState({ptpprice40tipper: event});
    }else if(stateid == "ptpprice40rf"){
      this.setState({ptpprice40rf: event});
    }
    
  }

 


  quotationbuttonclick = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})


    this.setState({loading:true})
    this.setState({tabs:2})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getquotationlistfinal'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  quotationnumber:json.user[i].Quotationnumber,
                  lastedit:json.user[i].LastEdit,
                },            
              ]})
              
              
             
               console.log(this.state.quotationtabledatafinal)
             

             
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  quotationdetailfinalclick = (shipperclicked,quotationnumberclicked) =>{
    console.log(this.state.Billtoparty)
    console.log(shipperclicked)
    console.log(quotationnumberclicked)
    this.setState({loadingmodal:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("Shipper", shipperclicked);
    urlencoded.append("quotationnumber", quotationnumberclicked);
    
    let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getquotationlistfinaldetail'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({
              detailbilltoparty:'',
              detailshipper:'',
              detailaddress:'',
              detailarea:'',
              detailpic:'',
              detaildepo:'',
              detailcontact:'',
              detailbacktobackcharge:'',
              pguprice20gp:'',
              pguprice20rf:'',
              pguprice20isotank:'',
              pguprice20ot:'',
              pguprice20tipper:'',
              pguprice40gp:'',
              pguprice40rf:'',
              pguprice40isotank:'',
              pguprice40tipper:'',
              
              ptpprice20gp:'',
              ptpprice20rf:'',
              ptpprice20isotank:'',
              ptpprice20ot:'',
              ptpprice20tipper:'',
              ptpprice40gp:'',
              ptpprice40rf:'',
              ptpprice40isotank:'',
              ptpprice40tipper:'',
            });   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.setState({
                detailbilltoparty:json.user[0].billtoparty,
                detailshipper:json.user[0].Shipper,
                detailaddress:json.user[0].Address,
                detailarea:json.user[0].Area,
                detailpic:json.user[0].Personincharge,
                detaildepo:json.user[0].depogatecharge,
                detailcontact:json.user[0].Contact,
                // detailbacktobackcharge:json.user[0].Address,
              })

              if(json.user[i].Pguorptp == 'PGU'){
                if(json.user[i].Size == 20){
                  if(json.user[i].Type == 'GP'){
                      this.setState({pguprice20gp:json.user[i].Price})
                  }else  if(json.user[i].Type == 'RF'){
                    this.setState({pguprice20rf:json.user[i].Price})
                  }else  if(json.user[i].Type == 'ISOTANK'){
                    this.setState({pguprice20isotank:json.user[i].Price})
                  }else  if(json.user[i].Type == 'OT'){
                    this.setState({pguprice20ot:json.user[i].Price})
                  }else  if(json.user[i].Type == 'Tipper'){
                    this.setState({pguprice20tipper:json.user[i].Price})
                  }
                }else{
                 
                    if(json.user[i].Type == 'GP'){
                      this.setState({pguprice40gp:json.user[i].Price})
                    }else  if(json.user[i].Type == 'RF'){
                      this.setState({pguprice40rf:json.user[i].Price})
                    }else  if(json.user[i].Type == 'ISOTANK'){
                      this.setState({pguprice40isotank:json.user[i].Price})
                    }else  if(json.user[i].Type == 'Tipper'){
                      this.setState({pguprice40tipper:json.user[i].Price})
                    }
                  
                }
              }else if(json.user[i].Pguorptp == 'PTP'){
                if(json.user[i].Size == 20){
                  if(json.user[i].Type == 'GP'){
                      this.setState({ptpprice20gp:json.user[i].Price})
                  }else  if(json.user[i].Type == 'RF'){
                    this.setState({ptpprice20rf:json.user[i].Price})
                  }else  if(json.user[i].Type == 'ISOTANK'){
                    this.setState({ptpprice20isotank:json.user[i].Price})
                  }else  if(json.user[i].Type == 'OT'){
                    this.setState({ptpprice20ot:json.user[i].Price})
                  }else  if(json.user[i].Type == 'Tipper'){
                    this.setState({ptpprice20tipper:json.user[i].Price})
                  }
                }else{
                 
                    if(json.user[i].Type == 'GP'){
                      this.setState({ptpprice40gp:json.user[i].Price})
                    }else  if(json.user[i].Type == 'RF'){
                      this.setState({ptpprice40rf:json.user[i].Price})
                    }else  if(json.user[i].Type == 'ISOTANK'){
                      this.setState({ptpprice40isotank:json.user[i].Price})
                    }else  if(json.user[i].Type == 'Tipper'){
                      this.setState({ptpprice40tipper:json.user[i].Price})
                    }
                  
                }
              
              }
            }        
            this.setState({loadingmodal:false})       
          }else{
            this.setState({loadingmodal:false})   
        console.log(json)
      }
    
     })
  }
 
  componentDidMount() {
    //this.quotationbuttonclickpending();
    this.quotationbuttonclick();
    }


  quotationbuttonclickpending = () =>{
    this.setState({buttoncolor1:"primary"})
    this.setState({buttoncolor2:"white"})


    this.setState({loading:true})
    this.setState({quotationtabledata:[]})
    this.setState({tabs:1})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = 'http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-getquotationpending'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({quotationtabledata:[]})
            var lengthemployee = Object.keys(json.user).length;
            for(let i=0;i<lengthemployee;i++){

              var statusword = ''
              if(json.user[i].Status == 1){
                statusword = 'Pending'
              } else{
                statusword = 'Cancelled'
              }
              this.setState({quotationtabledata:[...this.state.quotationtabledata,
                {no:i+1,
                  shippername:json.user[i].Shipper,
                  quotationnumber:json.user[i].Quotationnumber,
                  status:statusword,
                  lastedit:json.user[i].LastEdit,
                  shipperimage:json.user[i].ShipperImageUrl,
                },            
              ]})
              
              
             
              console.log(this.state.quotationtabledata)
             

             
              }
              this.setState({loading:false}) 
            }        
                   
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  
  renderTableData() {
      

      return this.state.quotationtabledata.map((data, index) => {
         const { no, shippername, quotationnumber, lastedit ,status,shipperimage} = data //destructuring
         const imgurllastedit = "http://reacttest.winnercloudstudio.com/img/profile_img/" + lastedit + ".jpg"
         //const shipperlogourl = "http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
         console.log(imgurllastedit)
         return (

          <tr>
          <td>{no})</td>
          <th scope="row">
            <Media className="align-items-center">
              
              <Media>
                <span className="mb-0 text-sm">
                   {shippername}
                </span>
              </Media>
            </Media>
          </th>
          <td>{quotationnumber}</td>
          <td>
            <Badge color="" className="badge-dot mr-4">
              <i className="bg-warning" />
              {status}
            </Badge>
          </td>
          <td className="d-md-inline-block">
            <div className="avatar-group d-md-inline-block">
            <a
                className="avatar avatar-sm"
                href="#pablo"
                id="tooltip996637554"
                onClick={e => e.preventDefault()}
              >
                <img
                  alt="..."
                  className="rounded-circle"
                  src={imgurllastedit}
                />
              </a>
             
              <UncontrolledTooltip
                delay={0}
                target="tooltip996637554"
              >
                {lastedit} 
              </UncontrolledTooltip>
           
            </div>
            <div className="mt-2 text-sm d-md-inline-block position-absolute">
                   06/04/2020
                </div>
          </td>                     
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  href="#pablo"
                  
                  onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                >
                  View/Edit
                </DropdownItem>
                 {/* <DropdownItem
                  href="#pablo"
                  onClick={() => this.toggleModal("editpopuout",shippername,quotationnumber)}
                >
                  View/Edit
                </DropdownItem> */}
                <DropdownItem
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Delete
                </DropdownItem>
               
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
         )
      })
   }




   renderTableFinalData() {
      

    return this.state.quotationtabledatafinal.map((data, index) => {
       const { no, shippername, quotationnumber, lastedit ,shipperimage} = data //destructuring
       const imgurllastedit = "http://reacttest.winnercloudstudio.com/img/profile_img/" + lastedit + ".jpg"
       //const shipperlogourl = "http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
       console.log(imgurllastedit)
       return (
        <tr>
        <td>{no})</td>
        <th scope="row">
          <Media className="align-items-center">
         
            <Media>
              <span className="mb-0 text-sm">
                {shippername}
              </span>
            </Media>
          </Media>
        </th>
       <td>{quotationnumber}</td>
       
        <td className="d-md-inline-block">
          <div className="avatar-group d-md-inline-block">
          <a
              className="avatar avatar-sm"
              href="#pablo"
              id="tooltip996637554"
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle"
                src={imgurllastedit}
              />
            </a>
            <UncontrolledTooltip
              delay={0}
              target="tooltip996637554"
            >
              {lastedit}
            </UncontrolledTooltip>
            <div className="mt-2 text-sm d-md-inline-block position-absolute">
                   06/04/2020
                </div>
          </div>
        </td>                     
        <td className="text-right">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={e => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                href="#pablo"
                onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
              >
                View/Edit
              </DropdownItem>
              <DropdownItem
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                Delete
              </DropdownItem>
              {/* <DropdownItem
                href="#pablo"
                onClick={() => this.toggleModal("editpopuout",shippername,quotationnumber)}
              >
                Edit
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
       
       )
    })
 }

  render() {

   return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid >
          {/* Table */}
          <Row>
            <Col md="8 ml-3">
            
                <div className="d-md-inline-block">
                <NavLink className="nav-link-icon d-md-inline-block" to="/admin/addnewquotation" tag={Link}>
              <Button className="my-4" color="default" type="button" style={{marginLeft:-10}}>
                + Add New Quotation
              </Button>
              </NavLink>
              <NavLink className="nav-link-icon d-md-inline-block">
              <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor1} type="button" style={{marginLeft:-10}} onClick={(e)=>this.quotationbuttonclickpending()}>
              <i className="ni ni-planet mr-2" />
                 Pending Approval
              </Button>
              </NavLink>
            
                </div>
         
                <NavLink className="nav-link-icon " >
              <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor2} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.quotationbuttonclick()}>
               <i className="ni ni-collection  mr-2" />
                 Quotation
              </Button>
              </NavLink>
              
            </Col>
            {/* <Col md="4">
            <div className=" col-8">
            <div className="nav-wrapper d-md-inline-block">
          {/* <Nav
            className="nav-fill flex-column flex-md-row d-md-inline-block"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
        
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 1
                })}
                onClick={e => this.toggleNavs(e, "tabs", 1)}
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-planet mr-2" />
                Pending Approval
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={this.state.tabs === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: this.state.tabs === 2
                })}
                onClick={e => this.toggleNavs(e, "tabs", 2)}
                href="#pablo"
                role="tab"
              >
                <i className="ni ni-collection  mr-2" />
                Quotation
              </NavLink>
            </NavItem>
            
          </Nav> */}
        {/* </div>
            </div>
            
           */}
            </Row>
          <Row >
          <div className=" col-12">
              <Card className=" shadow">
              <Form className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto mb--3">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> 
              <CardBody>
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              class
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
           
              :
               <TabContent activeTab={"tabs" + this.state.tabs}>
               <TabPane tabId="tabs1">
               <Table className="align-items-center" responsive>
                     <thead className="thead-light">
                       <tr>
                         <th scope="col">No.</th>
                         <th scope="col">Shipper/Consignee</th>
                         <th scope="col">Quotation Number</th>      
                         <th scope="col">Status</th>                  
                         <th scope="col">Last Edit</th>
                         <th scope="col">Edit</th>
                         <th scope="col" />
                       </tr>
                     </thead>
                     <tbody>
                     {this.renderTableData()} 
                     </tbody>
                   </Table>
               </TabPane>
               <TabPane tabId="tabs2">
               <Table className="align-items-center" responsive>
                     <thead className="thead-light">
                       <tr>
                         <th scope="col">No.</th>
                         <th scope="col">Shipper/Consignee</th>
                         <th scope="col">Quotation Number</th>                        
                         <th scope="col">Last Edit</th>
                         <th scope="col">Edit</th>
                         <th scope="col" />
                       </tr>
                     </thead>
                     <tbody>
                     {this.renderTableFinalData()} 
                   
                          
                     </tbody>
                   </Table>
               </TabPane>
              
             </TabContent>}
           
          </CardBody>
                
              </Card>
            </div>
          </Row>
        </Container>
     

        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.exampleModal}
          toggle={() => this.toggleModal("exampleModal")}
        >
         
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
           
          <div className="modal-body">
          {this.state.loadingmodal
              ?
              <Row className="align-items-center" responsive>
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              class
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
              </Row>
           
              :
          <Form>
          <Row>
            <Col md="12">
              <p className="h5">Bill To Party:</p>
              <FormGroup>
                <Input
                  disabled 
                  id="Billtopartyinput1"
                  placeholder={this.state.detailbilltoparty}
                  type="text"
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Shipper/Consignee:</p>
              <FormGroup>
              <Input
                  disabled 
                  id="Shipperinput1"
                  placeholder={this.state.detailshipper}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Address:</p>
              <FormGroup>
              <Input
                  disabled 
                  id="Addressinput1"
                  placeholder={this.state.detailaddress}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Area:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Areainput1"
                  placeholder={this.state.detailarea}
                  type="text"
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">PIC:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Picinput1"
                  placeholder={this.state.detailpic}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Depo Gate Charge:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Depogatechargeinput1"
                  placeholder={this.state.detaildepo}
                  type="text"
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">Contact:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Contactinput1"
                  placeholder={this.state.detailcontact}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Back to Back Charge:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Backtobackchargeinput1"
                  placeholder="Back to Back Charge"
                  type="text"
                />
              </FormGroup>
              </Col>
         
          </Row>
          <Row>
          <Col md="6">
            


          <p className="h5">PGU</p>
          <Table className="align-items-center" responsive>
                        
                        <thead className="thead-light-blue">
                          <tr>
                          <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.pguprice20gp}</td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td>{this.state.pguprice20rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td>{this.state.pguprice20isotank}</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td>{this.state.pguprice20ot}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td>{this.state.pguprice20tipper}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.pguprice40gp}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td>{this.state.pguprice40rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td>{this.state.pguprice40isotank}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td>{this.state.pguprice40tipper}</td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
          </Col>
          <Col md="6">
          
          <p className="h5">PTP</p>   
          <Table className="align-items-center" responsive>
                        <thead className="thead-light-blue">
                          <tr>
                            <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.ptpprice20gp}</td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td>{this.state.ptpprice20rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td>{this.state.ptpprice20isotank}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td>{this.state.ptpprice20ot}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td>{this.state.ptpprice20tipper}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.ptpprice40gp}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td>{this.state.ptpprice40rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td>{this.state.ptpprice40isotank}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td>{this.state.ptpprice40tipper}</td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
            </Col>
           
          </Row>
          <Row>
          <Col md="12">
               
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={() => { if (window.confirm('Are you sure you wish to edit this Quotation?')) this.toggleModal("exampleModal");this.toggleModal("editpopuout");this.setState({loadingmodal:false})} } >
              Edit
            </Button>
          </div>
      
              </Col>
         
          </Row>
        </Form>
            }
          </div>
       
       </Modal>

       
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.editpopuout}
          toggle={() => this.toggleModal("editpopuout")}
        >
          
          <div className="modal-header">
            <h5 className="modal-title" id="editpopuoutLabel">
              Edit
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("editpopuout")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

       
          <div className="modal-body p-10">
          {this.state.loadingmodal
              ?
              <Row className="align-items-center" responsive>
                    <Col md="12" >
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              class
              src={require("./../../assets/gif/lorrygif3.gif")}
            />  
              </Col>
              </Row>
           
              :
          <Form>
          <Row>
            <Col md="12">
              <p className="h5">Bill To Party:</p>
              <FormGroup>
                <Input
                  id="Billtopartyinput1"
                  placeholder="Bill To Party"
                  value={this.state.detailbilltoparty}
                  type="text"
                  onChange={e => this.handleChange(e.target.value,"detailbilltoparty")} 
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Shipper/Consignee:</p>
              <FormGroup>
              <Input
                  id="Shipperinput1"
                  placeholder="Shipper/Consignee"
                  type="text"
                  value={this.state.detailshipper}
                  onChange={e => this.handleChange(e.target.value,"detailshipper")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Address:</p>
              <FormGroup>
              <Input
                  id="Addressinput1"
                  placeholder="Address"
                  type="text"
                  value={this.state.detailaddress}
                  onChange={e => this.handleChange(e.target.value,"detailaddress")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Area:</p>
              <FormGroup>
              <Input
                  id="Areainput1"
                  placeholder="Area"
                  type="text"
                  value={this.state.detailarea}
                  onChange={e => this.handleChange(e.target.value,"detailarea")} 
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">PIC:</p>
              <FormGroup>
              <Input
                  id="Picinput1"
                  placeholder="PIC"
                  type="text"
                  value={this.state.detailpic}
                  onChange={e => this.handleChange(e.target.value,"detailpic")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Depo Gate Charge:</p>
              <FormGroup>
              <Input
                  id="Depogatechargeinput1"
                  placeholder="Depo Gate Charge"
                  value={this.state.detaildepo}
                  type="text"
                  onChange={e => this.handleChange(e.target.value,"detaildepo")} 
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">Contact:</p>
              <FormGroup>
              <Input
                  id="Contactinput1"
                  placeholder="Contact"
                  type="text"
                  value={this.state.detailcontact}
                  onChange={e => this.handleChange(e.target.value,"detailcontact")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Back to Back Charge:</p>
              <FormGroup>
              <Input
                  id="Backtobackchargeinput1"
                  placeholder="Back to Back Charge"
                  type="text"
               
                />
              </FormGroup>
              </Col>
         
          </Row>
          <Row>
          <Col md="6">
            
          <Table className="align-items-center" responsive>
            
                        <thead className="thead-light-blue">
                          <tr>
                          <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td>
                              <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  value={this.state.pguprice20gp}
                  type="number"
                  min="0"
                  onChange={e => this.handleChange(e.target.value,"pguprice20gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20rf}
                  onChange={e => this.handleChange(e.target.value,"pguprice20rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20isotank}
                  onChange={e => this.handleChange(e.target.value,"pguprice20isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20ot}
                  onChange={e => this.handleChange(e.target.value,"pguprice20ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20tipper}
                  onChange={e => this.handleChange(e.target.value,"pguprice20ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40gp}
                  onChange={e => this.handleChange(e.target.value,"pguprice40gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40rf}
                  onChange={e => this.handleChange(e.target.value,"pguprice40rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40isotank}
                  onChange={e => this.handleChange(e.target.value,"pguprice40isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40tipper}
                  onChange={e => this.handleChange(e.target.value,"pguprice40tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
          </Col>
          <Col md="6">
              
          <Table className="align-items-center" responsive>
                        <thead className="thead-light-blue">
                          <tr>
                            <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20gp}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20rf}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20isotank}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20ot}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20tipper}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40gp}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40rf}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40isotank}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40tipper}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
            </Col>
           
          </Row>
          <Row>
          <Col md="12" >
          <div className="modal-footer">
          <Button
              
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("editpopuout")}
            >
              Close
            </Button>
            <Button color="primary" type="button">
              Save changes
            </Button>
            </div>
              </Col>
            
          </Row>
        </Form>
          }
          
          </div>
        
            
        </Modal>
      </>
    );
    }
    
 
  }


export default Quotation;
